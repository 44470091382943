
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"02ce1977a26db133878a06f9832738fe2cf1196b":"getConfigNames","07a24be04851c8b455bba39d887b91348e785f6e":"getEntriesForApiKey","1bbe3a77465fd9e349ca98bad2f42dcfd05411a5":"populateConfigs","266a51defaf9922ddff57d5f8f965e3b44c136ba":"getEntryForApiKey","40964cd1818e4ba1d19024de6108da415c823f62":"createPasskey","797516fb9b88c8bc1f2d39a2fe755e87870d3c4b":"addNewConfig","8f7d93c97dbce46f15d9166cd482171c9b8ec8dd":"getAuthenticators","997ed670a1052fd0ec04a9d8fafde964bc527529":"signInAndCreate","d4f379ca4daa3c898766391a7a339eb4577ac80f":"updateConfig","d6237d6e75c7bbef3c67d9d79199ff4a740f9619":"updateConfigApiKey","e124b11bb23689de307a18f998b2eaf95fbd940e":"addNewLolEntry","e5c4d156759243faf1bf5a728da2b72da13cf43b":"deleteLoLEntry","f696ec7d4d59fb3011ea7e965cfc7ee12e88d9d5":"getEntry","f843f07ac6479c14515686d003632cdfc76a8307":"getEntriesForConfig"} */ export var addNewConfig = createServerReference("797516fb9b88c8bc1f2d39a2fe755e87870d3c4b");
import { createServerReference } from "private-next-rsc-action-client-wrapper";
export var updateConfigApiKey = createServerReference("d6237d6e75c7bbef3c67d9d79199ff4a740f9619");
export var createPasskey = createServerReference("40964cd1818e4ba1d19024de6108da415c823f62");
export var updateConfig = createServerReference("d4f379ca4daa3c898766391a7a339eb4577ac80f");
export var getEntriesForConfig = createServerReference("f843f07ac6479c14515686d003632cdfc76a8307");
export var getEntriesForApiKey = createServerReference("07a24be04851c8b455bba39d887b91348e785f6e");
export var getAuthenticators = createServerReference("8f7d93c97dbce46f15d9166cd482171c9b8ec8dd");
export var populateConfigs = createServerReference("1bbe3a77465fd9e349ca98bad2f42dcfd05411a5");
export var getEntryForApiKey = createServerReference("266a51defaf9922ddff57d5f8f965e3b44c136ba");
export var getEntry = createServerReference("f696ec7d4d59fb3011ea7e965cfc7ee12e88d9d5");
export var signInAndCreate = createServerReference("997ed670a1052fd0ec04a9d8fafde964bc527529");
export var addNewLolEntry = createServerReference("e124b11bb23689de307a18f998b2eaf95fbd940e");
export var getConfigNames = createServerReference("02ce1977a26db133878a06f9832738fe2cf1196b");
export var deleteLoLEntry = createServerReference("e5c4d156759243faf1bf5a728da2b72da13cf43b");

