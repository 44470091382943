'use client'
import { useEffect } from "react";
import Button from "react-bootstrap/Button";
import { useFormStatus } from "react-dom";
import SubmitProps  from "./SubmitProps";

function SubmitButton({updatePending = () =>{}, variant="primary",children="Submit", label="Submit", cssClass=""} :SubmitProps) {
    const formStatus = useFormStatus();

    useEffect( () => {
        updatePending(formStatus.pending)
    }, [formStatus.pending])

    return (
        <Button variant={variant} title={label} disabled={formStatus.pending} className={cssClass} type="submit">
            {children}
        </Button> 
    )
}
  
export default SubmitButton;