'use client'

import Badge from "react-bootstrap/Badge";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Alert from "react-bootstrap/Alert";
import Stack from "react-bootstrap/Stack";
import Table from "react-bootstrap/Table";
import SubmitButton from "@/app/components/SubmitButton";
import { addNewLolEntry, getEntriesForConfig, deleteLoLEntry, getEntriesForApiKey } from "@/app/actions/lol";
import { useState, useEffect, useActionState } from "react";
import { LoLEntry } from "@prisma/client";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { byPrefixAndName } from '@awesome.me/kit-180e59b8f3/icons'
import React from "react";
import { useSearchParams } from "next/navigation";

export default function Commands() {
  const [addFormState, addCommandAction] = useActionState(addNewLolEntry, true)
  const [commands, setCommands] = useState<LoLEntry[]>([])
  const [pending, setPending] = useState(false)
  const [validated, setValidated] = useState(false)
  const [addFormError, setAddError] = useState(false)
  const searchParams = useSearchParams()
  const updateEntries = () => {
    if (searchParams.has('key')) {
      getEntriesForApiKey(searchParams.get('key') ?? '').then(data => setCommands(data.entries))
    } else {
      getEntriesForConfig().then(data => setCommands(data.entries))
    }
  }

  useEffect(() => {
    setAddError(!addFormState)
    if(!addFormState) {
      setValidated(false)
    }
  }, [addFormState])
  
  useEffect(() => {
    updateEntries()
  }, [])

  useEffect(() => {
    if (!pending) {
      updateEntries()
    }
  }, [pending])

  const handleSub = (e: React.FormEvent<HTMLFormElement>) => {
    if (e.currentTarget.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
    }
    setValidated(true)
  }
  return (
    <><br /><h3 className="input-group-text">Commands&nbsp;<Badge bg="danger">{commands?.length}</Badge></h3>
      <Alert variant="danger" show={addFormError}>Error: Command already in use.</Alert> 
            
      <Form noValidate validated={validated} action={addCommandAction} onSubmit={handleSub}>
       
        {searchParams.has('key') && <Form.Control type="hidden" name="key" value={searchParams.get('key') ?? ''} />}
        <Stack gap={3} direction="horizontal">
            <FloatingLabel controlId="command" label="Command">
              <Form.Control required type='text' name="command" minLength={1} placeholder="Command" />
            </FloatingLabel>
            <FloatingLabel className="w-100" controlId="url" label="URL">
              <Form.Control required type="url" name="url" className="w-100" placeholder="URL" />
            </FloatingLabel>
            <SubmitButton cssClass="center" updatePending={setPending} variant="primary">
              <FontAwesomeIcon icon={byPrefixAndName.fas['plus']} />
            </SubmitButton>
        </Stack>
      </Form>
      <Table responsive striped bordered hover variant="dark">
        <thead>
          <tr>
            <th>Command</th>
            <th>URL</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {commands?.map((entry) => {
            return <tr key={entry.id}>
              <td>
                {entry.command}
              </td>
              <td>
                {entry.value}
              </td>
              <td>
                <Form action={deleteLoLEntry}>
                  <input type="hidden" name="id" value={entry.id} />
                  <SubmitButton updatePending={setPending} cssClass="" variant="danger">
                    <FontAwesomeIcon icon={byPrefixAndName.fad['trash-can']} />
                  </SubmitButton>
                </Form>
              </td>
            </tr>
          })}
        </tbody>
      </Table>
    </>
  )
}